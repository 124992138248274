import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, Pencil, Trash2, User } from 'lucide-react';
import ProfileModal from '../../../components/ProfileModal';
import { deleteProfile, fetchProfiles } from '../../../store/slice/profileSlice';

function Profile() {
  const dispatch = useDispatch();
  const { profiles, loading, error } = useSelector((state) => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    dispatch(fetchProfiles());
  }, [dispatch]);

  const handleEdit = (profile) => {
    setSelectedProfile(profile);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this profile?')) {
      await dispatch(deleteProfile(id));
    }
  };

  const handleCreateNew = () => {
    setSelectedProfile(null);
    setIsModalOpen(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-800" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          {error.message}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="border-b pb-4">
        <div className="flex justify-between items-center px-6">
          <div>
            <h1 className="text-2xl font-semibold tracking-tight">Profiles</h1>
            <p className="text-sm text-gray-500">
              Manage your professional profiles and expertise
            </p>
          </div>
          <button
            onClick={handleCreateNew}
            className="inline-flex items-center px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-md font-medium text-sm transition-colors"
          >
            <Plus className="h-4 w-4 mr-2" />
            New Profile
          </button>
        </div>
      </div>

      {/* Content Section */}
      <div className="px-6">
        {profiles.length === 0 ? (
          <div className="border-2 border-dashed rounded-lg">
            <div className="flex flex-col items-center justify-center py-12 space-y-4">
              <div className="h-12 w-12 rounded-full bg-purple-100 flex items-center justify-center">
                <User className="h-6 w-6 text-purple-600" />
              </div>
              <div className="text-center space-y-2">
                <h3 className="font-semibold">No profiles yet</h3>
                <p className="text-sm text-gray-500 max-w-sm">
                  Create your first profile to showcase your skills and expertise
                </p>
              </div>
              <button
                onClick={handleCreateNew}
                className="inline-flex items-center px-4 py-2 border border-gray-300 hover:border-gray-400 rounded-md text-sm font-medium transition-colors"
              >
                Create Profile
              </button>
            </div>
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
            {profiles.map((profile) => (
              <div
                key={profile.id}
                className="bg-white rounded-lg border shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="p-6">
                  <h3 className="text-lg font-semibold mb-3">{profile.title}</h3>
                  <div className="space-y-4">
                    <p className="text-sm text-gray-600 line-clamp-3">
                      {profile.about}
                    </p>
                    <div className="flex flex-wrap gap-2">
                      {profile.skills.map((skill) => (
                        <span
                          key={skill}
                          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                    <div className="flex justify-end gap-2 pt-4 border-t">
                      <button
                        onClick={() => handleEdit(profile)}
                        className="inline-flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 rounded-md hover:bg-gray-100 transition-colors"
                      >
                        <Pencil className="h-4 w-4 mr-1" />
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(profile.id)}
                        className="inline-flex items-center px-3 py-1.5 text-sm text-red-600 hover:text-red-900 rounded-md hover:bg-red-50 transition-colors"
                      >
                        <Trash2 className="h-4 w-4 mr-1" />
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <ProfileModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedProfile(null);
        }}
        profile={selectedProfile}
      />
    </div>
  );
}

export default Profile;