// PaymentPage.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../../components/PaymentPage/CheckoutForm';
import PlanSummary from '../../../components/PaymentPage/PlanSummary';
import endpoints from '../../../helpers/apiEndpoints';
import axiosInstance from '../../../utils/axiosInstance';

// Initialize Stripe (replace with your publishable key)
const stripePromise = loadStripe('pk_test_51PhCIWGGLFHD861J7XOGr1PAxRTmL4HYvjd9zg2hVyqeW1rFicJGJNQbqkEqWQvZSQcDpve0abGgrBUNt78LeOMO004mEooZYD');

const PaymentPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [clientSecret, setClientSecret] = useState("");
    const { allSubscriptions } = useSelector((state) => state.subscription);
    const [selectedPlan, setSelectedPlan] = useState(null);

    useEffect(() => {
        // Get planId from URL parameters
        const params = new URLSearchParams(location.search);
        const planId = params.get('planId');

        // Convert planId to number since URL params are always strings
        const planIdNumber = parseInt(planId, 10);

        console.log('Plan ID from URL:', planId);
        console.log('Converted Plan ID:', planIdNumber);
        console.log('All Subscriptions:', allSubscriptions);

        // Find the selected plan from allSubscriptions using the converted number
        const plan = allSubscriptions.find(p => p.id === planIdNumber);
        console.log('Found Plan:', plan);

        if (plan) {
            console.log(plan.price);

            setSelectedPlan(plan);
            // Create PaymentIntent on the server
            createPaymentIntent(plan.price);
        } else {
            console.warn('No matching plan found for ID:', planIdNumber);
        }
    }, [location, allSubscriptions]);

    const createPaymentIntent = async (amount) => {
        try {
            const response = await axiosInstance.post(`${endpoints.createPaymentIntent}`, { amount: Math.round(amount * 100) });
            console.log(response);

            if (response.status != 200) {
                throw new Error('Payment intent creation failed');
            }

            const data = await response.data;
            setClientSecret(data.clientSecret);
        } catch (error) {
            console.error('Error creating payment intent:', error);
            // Add error handling (show error message to user)
        }
    };

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#6E5DE9',
            colorBackground: '#ffffff',
            colorText: '#1A1A1A',
            colorDanger: '#df1b41',
            fontFamily: 'system-ui, sans-serif',
            borderRadius: '8px',
        }
    };

    if (!selectedPlan) {
        return (
            <div className="min-h-screen bg-[#F3F4FE] flex items-center justify-center">
                <div className="text-center">
                    <h2 className="text-xl text-gray-700">Loading plan details...</h2>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-[#F3F4FE] pb-16 pt-2 px-4 lg:px-24">
            {/* Header */}
            <div className="flex items-center justify-between w-full py-5 max-w-7xl mx-auto">
                <img src="../logo.png" className="w-32 lg:w-48" alt="Logo" />
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center gap-x-2 text-purple-600 hover:text-purple-800 font-semibold text-sm"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                    Cancel
                </button>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        {/* Left Column: Payment Form */}
                        <div className="p-6 lg:p-8">
                            <h1 className="text-2xl font-bold text-gray-900 mb-6">
                                Complete Your Purchase
                            </h1>
                            {clientSecret && (
                                <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                                    <CheckoutForm />
                                </Elements>
                            )}
                        </div>

                        {/* Right Column: Plan Summary */}
                        <div className="bg-gray-50 p-6 lg:p-8">
                            <PlanSummary plan={selectedPlan} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentPage;