// src/redux/features/profileSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
    profiles: [],
    loading: false,
    error: null,
    currentProfile: null
};

export const fetchProfiles = createAsyncThunk(
    'profiles/fetchProfiles',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get('/profiles');
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createProfile = createAsyncThunk(
    'profiles/createProfile',
    async (profileData, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/profiles', profileData);
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateProfile = createAsyncThunk(
    'profiles/updateProfile',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.put(`/profiles/${id}`, data);
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteProfile = createAsyncThunk(
    'profiles/deleteProfile',
    async (id, { rejectWithValue }) => {
        try {
            await axiosInstance.delete(`/profiles/${id}`);
            return id;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const profileSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        setCurrentProfile: (state, action) => {
            state.currentProfile = action.payload;
        },
        clearCurrentProfile: (state) => {
            state.currentProfile = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfiles.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProfiles.fulfilled, (state, action) => {
                state.loading = false;
                state.profiles = action.payload;
                state.error = null;
            })
            .addCase(fetchProfiles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createProfile.fulfilled, (state, action) => {
                state.profiles.push(action.payload);
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                const index = state.profiles.findIndex(p => p.id === action.payload.id);
                if (index !== -1) {
                    state.profiles[index] = action.payload;
                }
            })
            .addCase(deleteProfile.fulfilled, (state, action) => {
                state.profiles = state.profiles.filter(p => p.id !== action.payload);
            });
    }
});

export const { setCurrentProfile, clearCurrentProfile } = profileSlice.actions;
export default profileSlice.reducer;