import { performLogout } from "../../store/slice/authSlice";
import { ReactComponent as DashboardIcon } from '../../icons/dashboard.svg';
import { ReactComponent as JobsIcon } from '../../icons/jobs.svg';
import { ReactComponent as ProfileIcon } from '../../icons/profile.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../../icons/logout.svg';
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
const Sidebar = () => {
    const menuItems = [
        { name: 'Dashboard', icon: <DashboardIcon className="w-5 h-5" />, path: '/dashboard' },
        { name: 'Jobs', icon: <JobsIcon className="w-5 h-5" />, path: '/dashboard/jobs' },
        { name: 'Profiles', icon: <ProfileIcon className="w-5 h-5" />, path: '/dashboard/profile' },
        { name: 'Settings', icon: <SettingsIcon className="w-5 h-5" />, path: '/dashboard/settings' },
    ];
    const location = useLocation();

    const dispatch = useDispatch();

    const handleLogout = () => {
        const customDialog = document.createElement('div');
        customDialog.innerHTML = `
            <div id="logoutDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
                <div class="bg-white rounded-lg p-6 text-center w-80">
                    <h2 class="text-xl font-semibold mb-4 text-purple-600">Confirm Logout</h2>
                    <p class="mb-4">Are you sure you want to log out?</p>
                    <div class="flex justify-center space-x-4">
                        <button id="confirmLogout" class="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">Yes</button>
                        <button id="cancelLogout" class="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">Cancel</button>
                    </div>
                </div>
            </div>
        `;
        document.body.appendChild(customDialog);

        document.getElementById('confirmLogout').onclick = () => {
            document.body.removeChild(customDialog);
            dispatch(performLogout());
        };
        document.getElementById('cancelLogout').onclick = () => {
            document.body.removeChild(customDialog);
        };
    };


    return (
        <div className="w-1/5 bg-white text-grey2 h-screen flex flex-col items-center py-4">
            <div className="text-2xl font-bold mb-10 mt-6">
                <img className="h-20 w-auto" src="/logo.png" alt="" />
            </div>
            <hr className="w-[70%] border-t-2 border-grey2 mb-10" />
            <nav className="flex flex-col items-center space-y-4 w-full mb-10">
                {menuItems.map((item, index) => {
                    const isActive = location.pathname === item.path;

                    return (
                        <Link
                            key={index}
                            to={item.path}
                            className={`flex items-center space-x-2 p-2 w-4/5 mb-2 rounded ${isActive
                                ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#CD55F9] via-[#A159F2] to-[#6E5DE9]'
                                : 'text-grey2'
                                } hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#CD55F9] hover:via-[#A159F2] hover:to-[#6E5DE9]'`}
                        >
                            <div
                                className={`${isActive
                                    ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#CD55F9] via-[#A159F2] to-[#6E5DE9]'
                                    : 'text-grey2'
                                    } hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#CD55F9] hover:via-[#A159F2] hover:to-[#6E5DE9]'`}
                            >
                                {item.icon}
                            </div>
                            <div>{item.name}</div>
                        </Link>
                    );
                })}
                <button className="flex items-center space-x-2 p-2 w-4/5 mt-8" onClick={handleLogout}>
                    <div><LogoutIcon className="w-5 h-5" /></div>
                    <div className=" text-dangerRed">Log out</div>
                </button>
            </nav>
        </div>
    );
};

export default Sidebar;