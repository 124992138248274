const Pagination = ({ currentPage, totalPages, totalItems, itemsPerPage, handlePageChange }) => {
  // Calculate start and end item numbers
  const startItem = ((currentPage - 1) * itemsPerPage) + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  // Helper function to determine which pages to show
  const getPageNumbers = () => {
    const delta = 2; // Number of pages to show on either side of current page
    const range = [];
    const rangeWithDots = [];
    let l;

    // Calculate range
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || 
        i === totalPages ||
        (i >= currentPage - delta && i <= currentPage + delta)
      ) {
        range.push(i);
      }
    }

    // Add dots where needed
    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="my-6 flex flex-col sm:flex-row items-center justify-between gap-4 px-4">
      <div className="text-sm font-light text-[#808080]">
        Showing
        <span className="font-medium text-gray-700 px-1">
          {startItem} - {endItem}
        </span>
        of {totalItems} results
      </div>
      
      <div className="flex items-center gap-2">
        {/* Previous button for larger screens */}
        <button
          className="hidden sm:flex items-center gap-x-2 rounded-lg py-2 px-4 hover:bg-[#FCF4FE] bg-[#F5F5F5] disabled:opacity-50 disabled:hover:bg-[#F5F5F5]"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg 
            className="w-5 h-5 rotate-180" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M9 5l7 7-7 7"
            />
          </svg>
          <span className="bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
            Previous
          </span>
        </button>

        {/* Previous button for mobile */}
        <button
          className="sm:hidden flex items-center gap-x-2 rounded-lg py-2 px-4 hover:bg-[#FCF4FE] bg-[#F5F5F5] disabled:opacity-50 disabled:hover:bg-[#F5F5F5]"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg 
            className="w-5 h-5 rotate-180" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>

        {/* Page numbers */}
        <div className="hidden sm:flex gap-2">
          {pageNumbers.map((page, idx) => (
            page === '...' ? (
              <span 
                key={idx} 
                className="px-2 py-2 text-[#414D52]"
              >
                •••
              </span>
            ) : (
              <button
                key={idx}
                className={`rounded-lg py-2 px-4 font-medium transition-colors ${
                  currentPage === page 
                    ? 'bg-[#FCF4FE] text-[#6E5DE9]' 
                    : 'bg-[#F5F5F5] text-[#414D52] hover:bg-[#FCF4FE] hover:text-[#6E5DE9]'
                }`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            )
          ))}
        </div>

        {/* Current page indicator for mobile */}
        <span className="sm:hidden text-sm text-[#414D52]">
          Page {currentPage} of {totalPages}
        </span>

        {/* Next button for larger screens */}
        <button
          className="hidden sm:flex items-center gap-x-2 rounded-lg py-2 px-4 hover:bg-[#FCF4FE] bg-[#F5F5F5] disabled:opacity-50 disabled:hover:bg-[#F5F5F5]"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <span className="bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
            Next
          </span>
          <svg 
            className="w-5 h-5" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>

        {/* Next button for mobile */}
        <button
          className="sm:hidden flex items-center gap-x-2 rounded-lg py-2 px-4 hover:bg-[#FCF4FE] bg-[#F5F5F5] disabled:opacity-50 disabled:hover:bg-[#F5F5F5]"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <svg 
            className="w-5 h-5" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;