import React, { useEffect, useState } from 'react';
import { Search, X } from 'lucide-react';
import axiosInstance from '../utils/axiosInstance';

const SkillsSelector = ({ selectedSkills = [], onSkillsChange }) => {
    const [skills, setSkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const fetchSkills = async () => {
            try {
                const response = await axiosInstance.get('/jobs/skills');
                ;
                const result = response.data;

                if (!result) {
                    throw new Error(result.message || 'Failed to fetch skills');
                }

                setSkills(result.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSkills();
    }, []);

    const filteredSkills = skills.filter(skill =>
        skill.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !selectedSkills.includes(skill.name)
    );

    const handleSelectSkill = (skillName) => {
        if (!selectedSkills.includes(skillName)) {
            onSkillsChange([...selectedSkills, skillName]);
        }
        setSearchQuery('');
        setIsOpen(false);
    };

    const handleRemoveSkill = (skillToRemove) => {
        onSkillsChange(selectedSkills.filter(skill => skill !== skillToRemove));
    };

    if (error) {
        return (
            <div className="text-red-600 text-sm">
                Error loading skills: {error}
            </div>
        );
    }

    return (
        <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
                Skills
            </label>

            {/* Selected Skills Tags */}
            <div className="flex flex-wrap gap-2 mb-2">
                {selectedSkills.map((skill) => (
                    <span
                        key={skill}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm bg-purple-100 text-purple-800"
                    >
                        {skill}
                        <button
                            type="button"
                            onClick={() => handleRemoveSkill(skill)}
                            className="ml-1 hover:text-purple-900"
                        >
                            <X className="h-3 w-3" />
                        </button>
                    </span>
                ))}
            </div>

            {/* Search Input */}
            <div className="relative">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setIsOpen(true);
                    }}
                    onFocus={() => setIsOpen(true)}
                    placeholder="Search skills..."
                    className="w-full px-3 py-2 border rounded-md pr-10 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
                <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />

                {/* Dropdown List */}
                {isOpen && (searchQuery || filteredSkills.length > 0) && (
                    <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border max-h-60 overflow-auto">
                        {loading ? (
                            <div className="p-4 text-center text-gray-500">Loading skills...</div>
                        ) : filteredSkills.length > 0 ? (
                            <ul className="py-1">
                                {filteredSkills.map((skill) => (
                                    <li
                                        key={skill.id}
                                        className="px-4 py-2 hover:bg-purple-50 cursor-pointer text-sm"
                                        onClick={() => handleSelectSkill(skill.name)}
                                    >
                                        {skill.name}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="p-4 text-center text-gray-500">
                                No matching skills found
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SkillsSelector;