import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../helpers/apiEndpoints';
import { safeJSONParse } from '../../utils/safeJSONParse';

// Async thunk to fetch all subscriptions except the trial
export const fetchSubscriptions = createAsyncThunk(
    'public/subscription/fetchSubscriptions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${endpoints.getAllSubscriptions}`);
            return response.data.data; // Assuming the response has a 'subscriptions' field
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk to fetch the user's current subscription
export const fetchCurrentSubscription = createAsyncThunk(
    'subscription/fetchCurrentSubscription',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${endpoints.getUserSubscription}`);
            return response.data.data; // Assuming the response has 'subscriptionDetails'
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const startFreeTrial = createAsyncThunk(
    'subscription/startFreeTrial',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(endpoints.startFreeTrial);
            return response.data.data; // Assuming the response has a 'data' field with subscription details
        } catch (error) {
            return rejectWithValue({
                message: error.response?.data?.message || error.message || 'An error occurred',
            });
        }
    }
);

const initialState = {
    allSubscriptions: safeJSONParse(localStorage.getItem('allSubscriptions')) || [],
    currentSubscription: safeJSONParse(localStorage.getItem('currentSubscription')) || null,
    hasUserUsedTrial: localStorage.getItem('hasUserUsedTrial') === 'true',
    status: 'idle',
    error: null,
};

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setCurrentSubscription: (state, action) => {
            state.currentSubscription = action.payload;
            localStorage.setItem('currentSubscription', action.payload);
        },
        setHasUsedTrial: (state, action) => {
            state.hasUserUsedTrial = action.payload;
            localStorage.setItem('hasUserUsedTrial', action.payload);
        },
        setAllSubscriptions: (state, action) => {
            state.allSubscriptions = action.payload;
        },
        clearSubscriptionData: (state) => {
            state.currentSubscription = null;
            state.hasUserUsedTrial = false;
            localStorage.removeItem('currentSubscription');
            localStorage.removeItem('hasUserUsedTrial');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.allSubscriptions = action.payload;
                localStorage.setItem('allSubscriptions', JSON.stringify(action.payload));
                state.error = null;
            })
            .addCase(fetchSubscriptions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchCurrentSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCurrentSubscription.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currentSubscription = action.payload;
                localStorage.setItem('currentSubscription', JSON.stringify(action.payload));
                state.error = null;

                // Update hasUserUsedTrial based on the subscription
                const hasUsedTrial = action.payload && action.payload.name === 'Trial';
                state.hasUserUsedTrial = hasUsedTrial;
                localStorage.setItem('hasUserUsedTrial', hasUsedTrial);
            })
            .addCase(fetchCurrentSubscription.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(startFreeTrial.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(startFreeTrial.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currentSubscription = action.payload;
                state.hasUserUsedTrial = true;
                localStorage.setItem('currentSubscription', JSON.stringify(action.payload));
                localStorage.setItem('hasUserUsedTrial', 'true');
                state.error = null;
            })
            .addCase(startFreeTrial.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const {
    setCurrentSubscription,
    setHasUsedTrial,
    setAllSubscriptions,
    clearSubscriptionData,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
