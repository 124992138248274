import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createProfile, updateProfile } from '../store/slice/profileSlice';
import MultiSelect from "./common/multiSelect";
import { GradientButton } from "./common/button";
import SkillsSelector from './SkillsSelector';

export default function ProfileModal({ isOpen, onClose, profile }) {
    const dispatch = useDispatch();
    const languageOptions = [
        { value: "english", label: "English" },
        { value: "spanish", label: "Spanish" },
        { value: "french", label: "French" },
        { value: "german", label: "German" },
    ];

    const [formData, setFormData] = useState({
        title: '',
        about: '',
        skills: [],
        languages: [],
        profile_links: {
            linkedin: '',
            youtube: '',
            twitter: ''
        }
    });

    useEffect(() => {
        if (profile) {
            setFormData({
                title: profile.title || '',
                about: profile.about || '',
                skills: profile.skills || [],
                languages: profile.languages || [],
                profile_links: {
                    linkedin: profile.profile_links?.linkedin || '',
                    youtube: profile.profile_links?.youtube || '',
                    twitter: profile.profile_links?.twitter || ''
                }
            });
        } else {
            setFormData({
                title: '',
                about: '',
                skills: [],
                languages: [],
                profile_links: {
                    linkedin: '',
                    youtube: '',
                    twitter: ''
                }
            });
        }
    }, [profile]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (profile) {
            await dispatch(updateProfile({
                id: profile.id,
                data: formData
            }));
        } else {
            await dispatch(createProfile(formData));
        }
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center overflow-y-auto">
            <div className="relative min-h-screen py-6 flex items-start justify-center">
                <div className="bg-white rounded-lg w-[672px] my-8">
                    <div className="max-h-[90vh] overflow-y-auto">
                        <div className="p-8">
                            <h2 className="text-2xl font-bold mb-4">
                                {profile ? 'Edit Profile' : 'Create New Profile'}
                            </h2>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Profile Title"
                                        className="w-full p-2 border rounded"
                                        value={formData.title}
                                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                    />
                                </div>
                                <textarea
                                    rows={5}
                                    placeholder="About"
                                    className="bg-[#F5F5F5] p-3 border rounded-lg w-full placeholder:font-light"
                                    value={formData.about}
                                    onChange={(e) => setFormData({ ...formData, about: e.target.value })}
                                />
                                <SkillsSelector
                                    selectedSkills={formData.skills}
                                    onSkillsChange={(newSkills) => {
                                        setFormData(prev => ({
                                            ...prev,
                                            skills: newSkills
                                        }));
                                    }}
                                />
                                <MultiSelect
                                    label="Languages"
                                    options={languageOptions}
                                    value={formData.languages}
                                    onChange={(selected) => setFormData({ ...formData, languages: selected })}
                                />
                                <div className="space-y-4">
                                    {Object.entries({
                                        linkedin: 'LinkedIn',
                                        youtube: 'Youtube',
                                        twitter: 'X (Twitter)'
                                    }).map(([key, label]) => (
                                        <div key={key} className="relative">
                                            <h2 className="text-sm text-left text-[#1a1a1a] font-light">
                                                {label} Profile Link
                                            </h2>
                                            <div className="relative">
                                                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                    <img src={`../${key}.svg`} alt="" />
                                                </div>
                                                <input
                                                    type="text"
                                                    className="bg-[#F5F5F5] text-[#808080] placeholder:font-light text-sm rounded-lg block w-full pl-10 p-3"
                                                    placeholder="https://www."
                                                    value={formData.profile_links[key]}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            profile_links: {
                                                                ...formData.profile_links,
                                                                [key]: e.target.value
                                                            }
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="sticky bottom-0 bg-white pt-4 border-t mt-4 flex justify-end space-x-4">
                                    <button
                                        type="button"
                                        onClick={onClose}
                                        className="px-4 py-2 border rounded"
                                    >
                                        Cancel
                                    </button>
                                    <GradientButton type="submit">
                                        {profile ? 'Update' : 'Create'}
                                    </GradientButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}