import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import SEO from "../components/SEO";
import CompanySlide from "../components/CompanySlide";
import Upgrade from "../components/modals/Upgrade";
import Account from "../components/modals/Account";
import LoggingIn from "../components/modals/LoggingIn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSubscriptions } from "../store/slice/subscriptionSlice";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allSubscriptions, status, error } = useSelector((state) => state.subscription);
  const [modal, setModal] = useState(false);
  const [accountModal, setAcountModal] = useState(false);
  const [upgradeModal, setUpgardeModal] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Fetch subscriptions when component mounts
    if (!allSubscriptions.length || status === 'idle') {
      dispatch(fetchSubscriptions());
    }
  }, [dispatch, status, allSubscriptions.length]);

  const handleSelectPlan = (plan) => {
    navigate(`/payment?planId=${plan.id}`);
  };

  // Render loading state for plans
  const renderPlansContent = () => {
    if (status === 'loading') {
      return <div className="text-center py-10">Loading plans...</div>;
    }

    if (status === 'failed') {
      return <div className="text-center py-10 text-red-500">Error: {error?.message || 'Failed to load plans'}</div>;
    }

    return (
      <div className="grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 lg:grid-cols-3">
        {Array.isArray(allSubscriptions) && allSubscriptions.map((plan) => (
          <div key={plan.id}
            className={`relative flex flex-col items-center justify-center bg-[#fff] pt-5 pb-10 rounded-md shadow ${plan.name === 'Standard' ? 'border-2 border-purple-500' : ''
              }`}>
            <div className="w-full text-center space-y-2 border-b px-8 py-4">
              <h1 className="text-xs sm:text-base lg:text-xl font-semibold bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
                {plan.name.toUpperCase()} PLAN
              </h1>
              <p className="text-xs sm:text-base lg:text-xl text-[#000] font-[Gilroy-Bold] flex justify-center items-baseline gap-x-1">
                <span className="text-5xl lg:text-6xl">
                  ${parseFloat(plan.price).toFixed(2)}
                </span>
                /month
              </p>
            </div>
            <div className="space-y-2 my-7">
              {/* Max Profiles */}
              <div className="flex items-center gap-x-2">
                <img src="./check.svg" alt="" />
                <p className="font-light text-[#808080] text-center text-lg">
                  {plan.max_profiles == null
                    ? 'Unlimited Profiles'
                    : `${plan.max_profiles} Profile(s)`}
                </p>
              </div>

              {/* Max Proposals */}
              <div className="flex items-center gap-x-2">
                <img src="./check.svg" alt="" />
                <p className="font-light text-[#808080] text-center text-lg">
                  {plan.max_proposals == null
                    ? 'Unlimited Proposals'
                    : `${plan.max_proposals} Proposal(s)`}
                </p>
              </div>

              {/* Profile Optimization */}
              {plan.profile_optimization && (
                <div className="flex items-center gap-x-2">
                  <img src="./check.svg" alt="" />
                  <p className="font-light text-[#808080] text-center text-lg">
                    Profile Optimization Included
                  </p>
                </div>
              )}
            </div>
            <button
              onClick={() => handleSelectPlan(plan)}
              className={`flex items-center justify-center gap-x-2 w-[12rem] font-semibold text-sm transition-colors duration-300 px-2 py-4 rounded-md mx-auto ${plan.name === 'Standard'
                ? 'bg-purple-500 text-white hover:bg-purple-600'
                : 'bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:bg-gray-300'
                }`}
            >
              SELECT {plan.name.toUpperCase()} PLAN
            </button>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="text-gray-600">
      <SEO
        title="GigShark"
        description="Empowers Service Providers on Top Freelancing Platforms"
        name="GigShark"
        type="description"
      />
      {/* Hero ++++++++++++++++++++++++++ */}
      <div className="custom-gradient h-[34rem] sm:h-[40rem] md:h-[57rem]">
        <div className="container flex flex-col justify-center lg:px-10 mx-auto  py-5 md:py-20 ">
          <div className="mx-auto text-center  mb-40">
            <motion.p
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -300, opacity: 0 }}
              className="text-xs sm:text-sm lg:text-lg font-medium text-[#FCF4FE] text-center"
            >
              Find Your Perfect Freelance Job, Faster. Smarter. Easier
            </motion.p>
            <h1 className="text-[#fff] text-center text-xl md:text-3xl lg:text-4xl font-[Gilroy-Bold] font-semibold my-4  lg:tracking-[.16em]">
              Welcome to GigShark: <br /> The Future of Freelancing
            </h1>
            <motion.p
              initial={{ y: 300, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -300, opacity: 0 }}
              className="lg:w-[50rem] mx-auto text-sm lg:text-base font-light mb-5 text-[#fff] text-center px-2 sm:px-4 lg:px-0"
            >
              At Gigshark, we empower freelancers to score their dream jobs with
              AI-driven assistance designed to simplify and enhance your job
              search experience. Whether you’re seasoned professional or just
              starting, GigShark is your go-to platform for finding the perfect
              gig in no time
            </motion.p>

            <div className="mx-auto md:6/12 lg:w-7/12 flex  justify-center lg:justify-start items-center gap-3 mb-9 px-2">
              <button className="sm:w-[12rem] text-sm lg:text-lg   font-semibold transition duration-200 ">
                <img src="butt1.svg" className="w-full " alt="" />
              </button>

              <a href="https://chromewebstore.google.com/detail/gigshark-extension/nkpfaehkpmmlajcebgpjfpeondcjckog" target="_blank" className="sm:w-[12rem] text-sm lg:text-lg  font-semibold transition duration-200">
                <img src="butt2.svg" className="w-full" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container lg:px-10 mx-auto px-5 -mt-[8rem] sm:-mt-[15rem] md:-mt-[30rem]">
        <img src="./Dasboard.svg" alt="" className="w-full" />
      </div>

      {/* CTA +++++++++++++++++++++++++++++ */}

      <section className="mx-auto  justify-center  py-10 bg-[#FFF]">
        <h2 className="text-xl  text-[#1A1A1A] text-center md:text-3xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] px-3">
          GigShark{" "}
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            Empowers Service Providers
          </span>{" "}
          on Top Freelancing Platforms
        </h2>
        <div className="w-full pt-7">
          <CompanySlide />
        </div>
      </section>

      {/* About ***************************** */}
      <div
        id="about"
        className=" bg-[#fff] py-16 flex flex-col items-center  justify-center gap-x-1 px-5 lg:px-20"
      >
        <div className="container  space-y-6 md:space-y-0 lg:flex md:gap-6 lg:items-start py-5 md:py-10">
          <div className="lg:w-6/12">
            <p className="pb-3 font-light bg-gradient-to-b  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent lg:text-lg text-center lg:text-left">
              ABOUT GIGSHARK
            </p>
            <h2 className="text-xl text-[#212529] font-medium md:text-4xl lg:text-5xl font-[Gilroy-Bold] lg:tracking-[.10em] text-center lg:text-left">
              GigShark is an{" "}
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                AI-powered platform designed to revolutionize freelancing
              </span>
            </h2>
            <p className="mt-6 font-extralight text-[#808080] text-sm md:text-xl text-center lg:text-left">
              Our mission is to empower freelancers worldwide to unlock their
              full potential, earn more, and achieve greater success.
            </p>
            <p className="mt-3 font-extralight text-[#808080] text-sm md:text-xl text-center lg:text-left">
              {" "}
              With GigShark, freelancers gain access to cutting-edge AI tools
              that help them find the best job opportunities, boost
              productivity, and deliver outstanding results. By consistently
              exceeding client expectations, freelancers can build a stellar
              reputation and secure glowing reviews—creating a cycle of growth
              and success.
            </p>
            <p className="mt-3 font-extralight text-[#808080] text-sm md:text-xl text-center lg:text-left">
              GigShark is more than a platform; it’s your partner in achieving
              freelancing excellence.
            </p>
          </div>

          <div className="sm:w-[35rem] lg:w-auto">
            <img src="../pic4.png" alt="i" />
          </div>
        </div>

        <div className="w-full py-10 grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="text-center space-y-2">
            <h2 className="text-3xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent font-medium md:text-4xl font-[Gilroy-Bold] ">
              10,000+
            </h2>
            <p className="mt-6 font-light text-[#808080] text-sm lg:text-xl">
              Registered Freelancers
            </p>
          </div>

          <div className="text-center space-y-2">
            <h2 className="text-3xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent font-medium md:text-4xl font-[Gilroy-Bold] ">
              5,000+
            </h2>
            <p className="mt-6 font-light text-[#808080] text-sm lg:text-xl">
              Hot Jobs
            </p>
          </div>

          <div className="text-center space-y-2">
            <h2 className="text-3xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent font-medium md:text-4xl font-[Gilroy-Bold] ">
              5,000+
            </h2>
            <p className="mt-6 font-light text-[#808080] text-sm lg:text-xl">
              Daily Active Users
            </p>
          </div>
        </div>
      </div>

      {/* How Gigshark works ********************************** */}
      <div
        className="w-full bg-center bg-cover h-auto "
        style={{ "background-image": "url(./hiwbg.svg)" }}
      >
        <section className="mx-auto py-20 px-4 lg:px-24  flex flex-col  items-center  justify-center  ">
          <h2 className="text-2xl text-[#FFF] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
            How GigShark Works
          </h2>
          <div className="w-full py-5 lg:py-10 grid grid-cols-1 gap-2 lg:gap-4 md:grid-cols-4 ">
            <div className="md:col-span-2">
              <img src="./howbg.svg" alt="" />
            </div>

            <div className="w-full md:col-span-2 grid grid-cols-1 gap-8 md:gap-3 lg:gap-6 ">
              <div className="bg-[#fff] p-4 md:p-6 rounded-lg flex gap-x-3 justify-center items-center shadow-md">
                <img src="./con1.svg" className="w-12" alt="" />
                <div>
                  <img src="./Create.svg" alt="" />
                  <p className="mt-2 font-light text-[#62747d] text-sm lg:text-base">
                    Start by setting up a detailed profile that highlights your
                    skills, experience, and job preferences.
                  </p>
                </div>
              </div>
              <div className="bg-[#fff] p-4 md:p-6 rounded-lg flex gap-x-3 justify-center items-center shadow-md">
                <img src="./con2.svg" className="w-12" alt="" />
                <div>
                  <img src="./Get.svg" alt="" />
                  <p className="mt-2 font-light text-[#62747d] text-sm lg:text-base">
                    Our AI scans and analyzes job listings to present you with
                    the best opportunities.
                  </p>
                </div>
              </div>
              <div className="bg-[#fff] p-4 md:p-6 rounded-lg flex gap-x-3 justify-center items-center shadow-md">
                <img src="./con3.svg" className="w-12" alt="" />
                <div>
                  <img src="./Optimize.svg" alt="" />
                  <p className="mt-2 font-light text-[#62747d] text-sm lg:text-base">
                    Enhance your proposals, resumes, and cover letters with
                    relevant keywords and industry-specific phrases.
                  </p>
                </div>
              </div>
              <div className="bg-[#fff] p-4 md:p-6 rounded-lg flex gap-x-3 justify-center items-center shadow-md">
                <img src="./con4.svg" className="w-12" alt="" />
                <div>
                  <img src="./Apply.svg" alt="" />
                  <p className="mt-2 font-light text-[#62747d] text-sm lg:text-base">
                    Apply to jobs directly from the platform and track your
                    application status effortessly
                  </p>
                </div>
              </div>
              <div className="bg-[#fff] p-4 md:p-6 rounded-lg flex gap-x-3 justify-center items-center shadow-md">
                <img src="./con5.svg" className="w-12" alt="" />
                <div>
                  <img src="./Land.svg" alt="" />
                  <p className="mt-2 font-light text-[#62747d] text-sm lg:text-base">
                    Secure the job that perfectly matches your skills and
                    expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Why choose GigShark *********************************** */}
      <section className="mx-auto  bg-[#FCF4FE] lg:py-20 px-4 lg:px-24  flex flex-col  items-center  justify-center  ">
        <div className="w-full pt-5 lg:pt-10 grid grid-cols-1  md:grid-cols-3 gap-0.5">
          <div className=" py-4 md:py-8 rounded-lg">
            <h2 className="text-2xl text-[#1A1A1A] md:text-3xl lg:text-5xl pt-10 mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              Why choose <br />
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                GigShark
              </span>
            </h2>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                01
              </span>
            </h2>
            <img src="./01.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              our AI matches you with opportunities that best fit your skills
              and preferences.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                02
              </span>
            </h2>
            <img src="./02.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Receive tailored keyword recommendations that increase your
              visibility and relevance in your job applications.
            </p>
          </div>
        </div>
        <div className="w-full pb-5 lg:pb-10 lg:flex pt-0.5 gap-0.5">
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded col-span-3">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                03
              </span>
            </h2>
            <img src="./03.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Stay ahead of the competition with instant notifications about the
              latest Projects and Gig openings in your field.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded lg:w-[45rem]">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                04
              </span>
            </h2>
            <img src="./04.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Craft compelling proposals tailored to each job posting for
              maximum impact.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded col-span-2">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                05
              </span>
            </h2>
            <img src="./05.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Save time with ready-to-use templates that can be personalized to
              suit any job application
            </p>
          </div>
        </div>
      </section>

      {/* Features *********************************** */}
      <section
        id="features"
        className="mx-auto  bg-[#F3F4FE] py-28 px-4 lg:px-24  flex flex-col  items-center  justify-center  "
      >
        <h2 className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]   bg-clip-text text-transparent font-light  text-center text-base mb-4 mx-auto">
          FEATURES
        </h2>
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
          GigShark is a{" "}
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]   bg-clip-text text-transparent">
            powerful soluiton
          </span>
          , Redefining the way Freelancers work.
        </h2>
        <p className="mt-5 text-center font-light text-[#62747d] text-sm sm:w-[40rem] lg:w-[48rem] px-4">
          GigShark enables freelancers across the globe earn more money by
          getting jobs leveraging on AI, be more productive and do their best
          work which would need to positive reviews from clients and thereby
          boosting the reputation of the freelancer
        </p>
        <div className="w-full py-5 lg:py-10 grid grid-cols-1 gap-8 md:grid-cols-2 ">
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon1.svg" alt="" />
            <img src="./Web.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              Download GigShark Chrome Extension to flawlessly discover top gigs
              and apply <br />
              instantly.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon2.svg" alt="" />
            <img src="./Whatsapp.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              Never miss a job by enabling GigShark Whatsapp integration and get
              instant message with project details as soon as a project is
              posted.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon3.svg" alt="" />
            <img src="./Real.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              Your chances of winning a project are 9 times more if you apply
              within the first 10 minutes, GigShark ensures that you get instant
              job alerts.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon4.svg" alt="" />
            <img src="./AI.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              Our team has years of experience in freelancing and have developed
              winning models for Proposals. GigShark leverages AI to provide
              instant AI proposals to help you win more jobs.
            </p>
          </div>
        </div>
      </section>

      {/* Testimony **************** *****************/}
      <section className="mx-auto  bg-[#FFF] py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] tracking-[.12em] sm:w-[40rem] lg:w-[40rem] ">
          “Real Stories from{" "}
          <span className="bg-gradient-to-b  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            Real Customers
          </span>
          ”
        </h2>
        <h2 className="text-base bg-gradient-to-b  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]   bg-clip-text text-transparent font-light  text-center md:text-base my-4 mx-auto">
          Get inspired by these stories.
        </h2>

        <div className="w-full py-16 grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 px-5">
          <div className="bg-[#fff] space-y-2 p-5 lg:p-10 rounded-lg shadow-sm border text-center">
            <img src="./alex.svg" alt="" className="mx-auto w-14 lg:w-auto" />

            <div className="flex gap-x-2 items-start  pt-5">
              <img src="./quote.svg" alt="" className="mx-auto" />
              <p className=" font-light text-[#1A1A1A] text-sm">
                GigShark has completely transformed my freelancing game! The AI
                tools helped me land high-paying gigs, and my productivity has
                skyrocketed. The affordable pricing makes it a no-brainer—highly
                recommend!
              </p>
            </div>

            <div className="pt-7">
              <h2 className="text-sm text-[#1A1A1A] text-center md:text-lg mx-auto font-semibold ">
                Alex M.
              </h2>
              <p className="font-light text-[#808080] text-center text-xs">
                Graphic Designer
              </p>
            </div>
          </div>
          <div className="bg-[#fff] space-y-2 p-5 lg:p-10 rounded-lg shadow-sm border text-center lg:-mt-10 lg:mb-10">
            <img src="./priya.svg" alt="" className="mx-auto w-14 lg:w-auto" />

            <div className="flex gap-x-2 items-start  pt-5">
              <img src="./quote.svg" alt="" className="mx-auto" />
              <p className=" font-light text-[#1A1A1A] text-sm">
                I used to struggle finding consistent work, but with GigShark,
                opportunities come to me. The platform makes it easy to deliver
                my best work, and my client reviews have never been better. It's
                the best investment I've made in my career.
              </p>
            </div>

            <div className="pt-7">
              <h2 className="text-sm text-[#1A1A1A] text-center md:text-lg mx-auto font-semibold ">
                Priya S.
              </h2>
              <p className="font-light text-[#808080] text-center text-xs">
                Software Developer
              </p>
            </div>
          </div>
          <div className="bg-[#fff] space-y-2 p-5 lg:p-10 rounded-lg shadow-sm border text-center">
            <img src="./james.svg" alt="" className="mx-auto w-14 lg:w-auto" />

            <div className="flex gap-x-2 items-start  pt-5">
              <img src="./quote.svg" alt="" className="mx-auto" />
              <p className=" font-light text-[#1A1A1A] text-sm">
                Thanks to GigShark, I’m earning more and working smarter. The AI
                job-matching feature is incredible, and the productivity tools
                save me hours every week. It’s affordable and worth every penny!
              </p>
            </div>

            <div className="pt-7">
              <h2 className="text-sm text-[#1A1A1A] text-center md:text-lg mx-auto font-semibold ">
                James L.
              </h2>
              <p className="font-light text-[#808080] text-center text-xs">
                Web Developer
              </p>
            </div>
          </div>
        </div>

        <button className="flex items-center justify-center gap-x-2 lg:w-[20rem]  text-sm custom-gradient transition-colors duration-300  p-3 rounded-md hover:text-[#fff] text-[#FFF] font-semibold uppercase hover:bg-[#c0c5c5] mx-auto ">
          EXPLORE ALL CUSTOMER STORIES
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.207 18.7069C17.0184 18.8891 16.7658 18.9899 16.5036 18.9876C16.2414 18.9853 15.9906 18.8801 15.8052 18.6947C15.6198 18.5093 15.5146 18.2585 15.5123 17.9963C15.51 17.7341 15.6108 17.4815 15.793 17.2929L20.086 12.9999H2.5C2.23478 12.9999 1.98043 12.8946 1.79289 12.707C1.60536 12.5195 1.5 12.2651 1.5 11.9999C1.5 11.7347 1.60536 11.4803 1.79289 11.2928C1.98043 11.1053 2.23478 10.9999 2.5 10.9999H20.086L15.793 6.70692C15.6108 6.51832 15.51 6.26571 15.5123 6.00352C15.5146 5.74132 15.6198 5.49051 15.8052 5.3051C15.9906 5.11969 16.2414 5.01452 16.5036 5.01224C16.7658 5.00997 17.0184 5.11076 17.207 5.29292L23.207 11.2929C23.3945 11.4804 23.4998 11.7348 23.4998 11.9999C23.4998 12.2651 23.3945 12.5194 23.207 12.7069L17.207 18.7069Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </section>

      {/* Pricing Section - Updated */}
      <div id="pricing" className="bg-[#F3F4FE] py-16 px-5 lg:px-24 flex flex-col items-center justify-center gap-x-1">
        <div className="container mx-auto py-10">
          <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem]">
            <span className="bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
              Affordable Pricing{" "}
            </span>
            for Everyone
          </h2>
          <p className="mt-5 text-center font-light text-[#1a1a1a] text-sm sm:w-[40rem] lg:w-[48rem] mx-auto">
            Unlock premium AI-powered freelancing tools without breaking the bank.
            GigShark offers top-tier solutions at prices every freelancer can afford!
          </p>

          <button className="w-[15rem] flex justify-center my-7 text-sm transition-colors duration-300 p-px rounded-md bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-bold uppercase hover:bg-[#F5F5F5] mx-auto">
            <button onClick={() => navigate("/signup")} className="bg-white w-full p-3 rounded-md">
              <span className="bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
                GET STARTED FOR FREE
              </span>
            </button>
          </button>

          {renderPlansContent()}
        </div>
      </div>

      {/* Tagline / infinte scrolling *********************************** */}
      <section className="mx-auto  bg-[#FFF] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center space-y-5 ">
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
          GigShark Alerts & Automation{" "}
        </h2>
        <marquee behavior="" direction="">
          <h2 className="text-xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] opacity-70 bg-clip-text text-transparent text-center md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]">
            - Empowering Freelancers and Agencies To Do Their Best Work
          </h2>
        </marquee>
      </section>

      {/* Banner ************************************/}
      <div
        className="w-full bg-center bg-cover lg:h-[35rem] "
        style={{ "background-image": "url(./wcubg.svg)" }}
      >
        <div className="container m-auto space-y-6 md:space-y-0 lg:flex md:gap-6 justify-end lg:items-end p-3 md:py-28 md:px-20 bg-[#800080] lg:bg-transparent ">
          <div className="lg:w-6/12">
            <h2 className="text-xl text-[#FFF] font-medium md:text-4xl lg:text-5xl font-[Gilroy-Bold] lg:tracking-[.10em] text-center lg:text-left">
              Ready to Dive into the Gig Economy?
            </h2>
            <p className="mt-3 lg:mt-6 font-extralight text-[#FFF] text-sm md:text-xl text-center lg:text-left">
              Join GigShark today and take the freelancing world by storm! Let
              our AI handle the job hunt while you ive straight into what you do
              best. No more guesswork - just pure, unstoppable hustle. Get in,
              get noticed, and get paid!
            </p>
            <div className="flex">
              <button className="w-[15rem] flex justify-center mt-7 text-sm  transition-colors duration-300 p-px  rounded-md  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  font-bold uppercase hover:bg-[#F5F5F5]">
                <div className="bg-white w-full p-3 rounded-md">
                  <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GET STARTED FOR FREE
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Email Sub *********************************** */}
      <section className="mx-auto  bg-[#FFF] py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center lg:space-y-5 ">
        <h2 className="text-2xl text-[#000] font-[Gilroy-Bold]  text-center md:text-4xl lg:text-5xl mx-auto lg:tracking-[.10em]">
          Be the first to know about
        </h2>
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            new features
          </span>
        </h2>
        <p className="mt-5 text-center font-light text-[#62747d] text-sm px-6 lg:px-0 sm:w-[40rem] lg:w-[48rem] mx-auto ">
          Enter your email address to stay ahead of others by getting timely
          updates on new features.
        </p>
        <div className="flex flex-col lg:flex-row w-[17rem] gap-3 lg:gap-0 justify-center items-center  lg:w-[33rem] my-2">
          <input
            // value={email}
            type="email"
            placeholder="Email Address"
            // onChange={(e) => {
            //   setEmail(e.target.value);
            // }}
            className="block w-full font-extralight placeholder-[#808080] rounded-md border border-gray-[#808080] bg-white px-2.5 py-3 text-[#808080] focus:outline-none required"
          />
          <button className="lg:-ml-3 flex items-center justify-center gap-x-2 w-[12rem] md:w-[12rem]font-semibold  text-sm custom-gradient  transition-colors duration-300  px-2 py-3 rounded-r-md hover:text-[#800080] text-[#FFF] font-semibolds uppercase hover:bg-[#c0c5c5] mx-auto ">
            SUBSCRIBE
            <img src="./send.svg" alt="" />
          </button>
        </div>
      </section>

      {/* Blog ************** **************** */}
      <div
        id="blog"
        className=" bg-[#F3F4FE] py-16 px-4 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 "
      >
        <div className="container  mx-auto py-10">
          <h2 className="text-2xl text-[#000] font-[Gilroy-Bold]  pb-10 text-center md:text-5xl lg:w-[35rem] mx-auto">
            Blog Posts & Articles
          </h2>
          <div className=" grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 lg:grid-cols-3">
            <div>
              <img
                className="object-cover object-center w-full h-64 rounded-t-lg lg:h-80"
                src="./pic5.png"
                alt=""
              />

              <div className="p-4 bg-[#fff]">
                <div className="flex items-center gap-x-2">
                  <h1 className="text-xs sm:text-sm font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GIG ECONOMY
                  </h1>{" "}
                  -
                  <p className="text-xs sm:text-sm  text-[#808080] font-semibold flex items-center gap-x-1">
                    <span className="bg-[#7E7E7E] rounded-full h-1 w-1"></span>
                    15 AUGUST
                  </p>
                </div>
                <h1 className="mt-4 text-xl font-semibold text-gray-800">
                  Everything You Need to Know About the World of Freelancing
                </h1>
              </div>
            </div>
            <div>
              <img
                className="object-cover object-center w-full h-64 rounded-t-lg lg:h-80"
                src="./pic7.png"
                alt=""
              />

              <div className="p-4 bg-[#fff]">
                <div className="flex items-center gap-x-2">
                  <h1 className="text-xs sm:text-sm font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GIG ECONOMY
                  </h1>{" "}
                  -
                  <p className="text-xs sm:text-sm  text-[#808080] font-semibold flex items-center gap-x-1">
                    <span className="bg-[#7E7E7E] rounded-full h-1 w-1"></span>
                    15 AUGUST
                  </p>
                </div>
                <h1 className="mt-4 text-xl font-semibold text-gray-800">
                  Everything You Need to Know About the World of Freelancing
                </h1>
              </div>
            </div>
            <div>
              <img
                className="object-cover object-center w-full h-64 rounded-t-lg lg:h-80"
                src="./pic8.png"
                alt=""
              />

              <div className="p-4 bg-[#fff]">
                <div className="flex items-center gap-x-2">
                  <h1 className="text-xs sm:text-sm font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GIG ECONOMY
                  </h1>{" "}
                  -
                  <p className="text-xs sm:text-sm  text-[#808080] font-semibold flex items-center gap-x-1">
                    <span className="bg-[#7E7E7E] rounded-full h-1 w-1"></span>
                    15 AUGUST
                  </p>
                </div>
                <h1 className="mt-4 text-xl font-semibold text-gray-800">
                  Everything You Need to Know About the World of Freelancing
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Logining In Modal ********************* */}
      {modal && <LoggingIn />}

      {/* Account Modal ********************* */}
      {accountModal && <Account />}

      {/* Upgrade Modal ********************* */}
      {upgradeModal && <Upgrade />}
    </div>
  );
}

export default Home;
