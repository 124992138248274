import React, { useEffect, useState } from "react";
import endpoints from "../../helpers/apiEndpoints";
import axiosInstance from "../../utils/axiosInstance";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Pencil, Check, X } from 'lucide-react';

export default function GenerateProposal() {
  const { id: jobId } = useParams();
  const [screen, setScreen] = useState(1);
  const [portfolioId, setPortfolioId] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [aiModel, setAiModel] = useState("GPT-4o");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [generatedText, setGeneratedText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { selectedJob } = useSelector((state) => state.job);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axiosInstance.get(endpoints.getJobProfiles);
        setProfiles(response.data.data);
      } catch (err) {
        setError("Failed to load profiles");
        console.error("Error fetching profiles:", err);
      }
    };

    fetchProfiles();
  }, []);

  useEffect(() => {
    setEditedText(generatedText);
  }, [generatedText]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(isEditing ? editedText : generatedText);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setError('Failed to copy to clipboard');
    }
  };

  const handleApplyOnUpwork = async () => {
    try {
      await navigator.clipboard.writeText(isEditing ? editedText : generatedText);
      setCopySuccess(true);
      if (selectedJob?.url) {
        window.open(selectedJob.url, '_blank');
      }
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setError('Failed to copy to clipboard');
      if (selectedJob?.url) {
        window.open(selectedJob.url, '_blank');
      }
    }
  };

  const handleGenerate = async () => {
    try {
      setLoading(true);
      setError(null);
      setIsEditing(false);

      const payload = {
        portfolio: portfolioId,
        jobId: jobId,
        aiModel: aiModel,
        extraInformation: additionalInfo
      };

      const response = await axiosInstance.post(endpoints.generateCoverLetter, payload);
      setGeneratedText(response.data.data || "No cover letter generated.");
      setScreen(2);
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleRewrite = async () => {
    try {
      setLoading(true);
      setError(null);
      setIsEditing(false);

      const payload = {
        portfolio: portfolioId,
        jobId: jobId,
        aiModel: aiModel,
        extraInformation: additionalInfo,
        previousText: isEditing ? editedText : generatedText
      };

      const response = await axiosInstance.post(endpoints.generateCoverLetter, payload);
      setGeneratedText(response.data.data || "No cover letter generated.");
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      setGeneratedText(editedText);
    }
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    setEditedText(generatedText);
    setIsEditing(false);
  };

  return (
    <div className="bg-white p-8">
      <div className={screen === 1 ? "" : "hidden"}>
        <div className="w-[35rem] space-y-5">
          <div className="w-full">
            <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-base">
              Select profile
            </h2>
            <div className="w-full">
              <div className="relative">
                <select
                  value={portfolioId}
                  onChange={(e) => setPortfolioId(e.target.value)}
                  className="py-2 my-1 block appearance-none border text-[#1a1a1a] bg-[#F5F5F5] p-3 border rounded-lg w-full focus:outline-none px-5 text-sm"
                >
                  <option value="">Select a profile</option>
                  {profiles.map((profile) => (
                    <option key={profile.id} value={profile.id}>
                      {profile.title}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-base">
              AI Model
            </h2>
            <div className="w-full">
              <div className="relative">
                <select
                  value={aiModel}
                  onChange={(e) => setAiModel(e.target.value)}
                  className="py-2 my-1 block appearance-none border text-[#1a1a1a] bg-[#F5F5F5] p-3 border rounded-lg w-full focus:outline-none px-5 text-sm"
                >
                  <option value="GPT-4o-mini">GPT-4o Mini</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-base">
              Additional Info
            </h2>
            <textarea
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              cols="30"
              rows="6"
              placeholder="Enter anything else to back up your application and make it stand out"
              className="bg-[#F5F5F5] p-3 border rounded-lg w-full text-sm placeholder:font-light"
            />
          </div>

          <button
            onClick={handleGenerate}
            disabled={loading || !portfolioId}
            className="w-[12rem] text-center font-bold text-sm custom-gradient transition-colors duration-300 p-4 rounded hover:text-[#fff] text-[#FFF] hover:bg-[#c0c5c5] mx-auto"
          >
            {loading ? "Generating..." : "Generate"}
          </button>

          {error && (
            <p className="text-red-500 text-sm">
              {error}
            </p>
          )}
        </div>
      </div>

      <div className={screen === 2 ? "" : "hidden"}>
        <div className="w-full space-y-5">
          <div className="relative">
            {isEditing ? (
              <textarea
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
                className="bg-[#F5F5F5] p-3 border-2 border-purple-400 rounded-lg w-full text-xs text-[#808080] font-light min-h-[200px]"
              />
            ) : (
              <p className="bg-[#F5F5F5] p-3 border rounded-lg w-full text-xs text-[#808080] font-light whitespace-pre-wrap">
                {generatedText}
              </p>
            )}

            <div className="absolute top-3 right-3 flex gap-2">
              {isEditing ? (
                <>
                  <button
                    onClick={handleEditToggle}
                    className="p-2 bg-green-100 rounded-full hover:bg-green-200 transition-colors"
                    title="Save changes"
                  >
                    <Check className="h-4 w-4 text-green-600" />
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="p-2 bg-red-100 rounded-full hover:bg-red-200 transition-colors"
                    title="Cancel editing"
                  >
                    <X className="h-4 w-4 text-red-600" />
                  </button>
                </>
              ) : (
                <button
                  onClick={handleEditToggle}
                  className="p-2 bg-purple-100 rounded-full hover:bg-purple-200 transition-colors"
                  title="Edit text"
                >
                  <Pencil className="h-4 w-4 text-purple-600" />
                </button>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <button
                onClick={handleRewrite}
                disabled={loading}
                className="w-full md:w-[10rem] text-center font-semibold text-sm bg-[#FCF4FE] hover-gradient transition-colors duration-300 p-4 rounded hover:bg-[#c0c5c5]/10"
              >
                <span className="bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
                  {loading ? "Rewriting..." : "Rewrite"}
                </span>
              </button>
              <button
                onClick={handleApplyOnUpwork}
                className="w-[12rem] text-center font-bold text-sm custom-gradient transition-colors duration-300 p-4 rounded hover:text-[#fff] text-[#FFF] hover:bg-[#c0c5c5] mx-auto"
              >
                Apply on Upwork
              </button>
            </div>

            <div className="flex items-center gap-x-3">
              <div className="flex items-center gap-x-3">
                <button onClick={handleCopy}
                  className="relative"
                  aria-label="Copy to clipboard"
                >
                  {copySuccess && (
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2">
                      Copied!
                    </div>
                  )}
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="40"
                      y="40"
                      width="40"
                      height="40"
                      rx="20"
                      transform="rotate(-180 40 40)"
                      fill="#FCF4FE"
                    />
                    <path
                      d="M29 16.94C28.9896 16.8481 28.9695 16.7576 28.94 16.67V16.58C28.8919 16.4772 28.8278 16.3827 28.75 16.3L22.75 10.3C22.6673 10.2222 22.5728 10.1581 22.47 10.11C22.4402 10.1058 22.4099 10.1058 22.38 10.11C22.2784 10.0517 22.1662 10.0143 22.05 10H18C17.2044 10 16.4413 10.3161 15.8787 10.8787C15.3161 11.4413 15 12.2044 15 13V14H14C13.2044 14 12.4413 14.3161 11.8787 14.8787C11.3161 15.4413 11 16.2044 11 17V27C11 27.7956 11.3161 28.5587 11.8787 29.1213C12.4413 29.6839 13.2044 30 14 30H22C22.7956 30 23.5587 29.6839 24.1213 29.1213C24.6839 28.5587 25 27.7956 25 27V26H26C26.7956 26 27.5587 25.6839 28.1213 25.1213C28.6839 24.5587 29 23.7956 29 23V17C29 17 29 17 29 16.94ZM23 13.41L25.59 16H24C23.7348 16 23.4804 15.8946 23.2929 15.7071C23.1054 15.5196 23 15.2652 23 15V13.41ZM23 27C23 27.2652 22.8946 27.5196 22.7071 27.7071C22.5196 27.8946 22.2652 28 22 28H14C13.7348 28 13.4804 27.8946 13.2929 27.7071C13.1054 27.5196 13 27.2652 13 27V17C13 16.7348 13.1054 16.4804 13.2929 16.2929C13.4804 16.1054 13.7348 16 14 16H15V23C15 23.7956 15.3161 24.5587 15.8787 25.1213C16.4413 25.6839 17.2044 26 18 26H23V27ZM27 23C27 23.2652 26.8946 23.5196 26.7071 23.7071C26.5196 23.8946 26.2652 24 26 24H18C17.7348 24 17.4804 23.8946 17.2929 23.7071C17.1054 23.5196 17 23.2652 17 23V13C17 12.7348 17.1054 12.4804 17.2929 12.2929C17.4804 12.1054 17.7348 12 18 12H21V15C21 15.7956 21.3161 16.5587 21.8787 17.1213C22.4413 17.6839 23.2044 18 24 18H27V23Z"
                      fill="url(#paint0_linear_128_3443)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_128_3443"
                        x1="27.1656"
                        y1="13.3758"
                        x2="15.5623"
                        y2="7.01208"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#CD55F9" />
                        <stop offset="0.486667" stop-color="#A159F2" />
                        <stop offset="1" stop-color="#6E5DE9" />
                      </linearGradient>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
