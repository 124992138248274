const JobCardSkeleton = () => {
    return (
      <div className="bg-white rounded-lg p-4 border animate-pulse">
        <div className="flex justify-between items-start">
          <div className="w-3/4">
            {/* Title */}
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
            {/* Description */}
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              <div className="h-4 bg-gray-200 rounded w-4/6"></div>
            </div>
          </div>
          {/* Budget */}
          <div className="h-8 bg-gray-200 rounded w-24"></div>
        </div>
        
        {/* Skills */}
        <div className="mt-4 flex flex-wrap gap-2">
          <div className="h-6 bg-gray-200 rounded w-16"></div>
          <div className="h-6 bg-gray-200 rounded w-20"></div>
          <div className="h-6 bg-gray-200 rounded w-24"></div>
        </div>
        
        {/* Footer */}
        <div className="mt-4 flex justify-between items-center">
          <div className="h-4 bg-gray-200 rounded w-32"></div>
          <div className="h-8 bg-gray-200 rounded w-24"></div>
        </div>
      </div>
    );
  };
  
  export default JobCardSkeleton;