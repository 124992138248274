import React from 'react';

const PlanSummary = ({ plan }) => {
    // Convert price to number if it's a string
    const basePrice = Number(plan.price);
    const calculateTax = (amount) => amount * 0.08; // Example 8% tax rate
    const tax = calculateTax(basePrice);
    const total = basePrice + tax;

    return (
        <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-900">Order Summary</h2>
            
            {/* Plan Details */}
            <div className="space-y-4">
                <div className="flex justify-between items-center">
                    <div>
                        <h3 className="font-medium text-gray-900">{plan.name} Plan</h3>
                        <p className="text-sm text-gray-500">Monthly Subscription</p>
                    </div>
                    <span className="font-medium text-gray-900">
                        ${basePrice.toFixed(2)}
                    </span>
                </div>

                {/* Features */}
                <div className="space-y-2 border-t pt-4">
                    <h4 className="text-sm font-medium text-gray-900">Included Features:</h4>
                    <ul className="space-y-2">
                        <li className="flex items-center text-sm text-gray-600">
                            <svg className="h-4 w-4 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M5 13l4 4L19 7"></path>
                            </svg>
                            {plan.max_profiles ? `${plan.max_profiles} Profiles` : 'Unlimited Profiles'}
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                            <svg className="h-4 w-4 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M5 13l4 4L19 7"></path>
                            </svg>
                            {plan.max_proposals ? `${plan.max_proposals} Proposals` : 'Unlimited Proposals'}
                        </li>
                        {plan.profile_optimization && (
                            <li className="flex items-center text-sm text-gray-600">
                                <svg className="h-4 w-4 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M5 13l4 4L19 7"></path>
                                </svg>
                                Profile Optimization
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            {/* Price Breakdown */}
            <div className="border-t pt-4 space-y-2">
                <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Subtotal</span>
                    <span className="text-gray-900">${basePrice.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Tax (8%)</span>
                    <span className="text-gray-900">${tax.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-base font-medium pt-2 border-t">
                    <span className="text-gray-900">Total</span>
                    <span className="text-purple-600">${total.toFixed(2)}</span>
                </div>
            </div>

            {/* Security Notice */}
            <div className="text-xs text-gray-500 space-y-1 border-t pt-4">
                <div className="flex items-center">
                    <svg className="h-4 w-4 text-gray-400 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                    <span>Secure payment processed by Stripe</span>
                </div>
                <p>Your payment information is encrypted and secure.</p>
            </div>
        </div>
    );
};

export default PlanSummary;