import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../helpers/apiEndpoints';
import { safeJSONParse } from '../../utils/safeJSONParse';

// Async thunk to fetch jobs with search and pagination
export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async ({ page = 1, limit = 10, search = '' }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(
                `${endpoints.getAllJobs}?page=${page}&limit=${limit}${search ? `&search=${encodeURIComponent(search)}` : ''}`
            );
            return {
                data: response.data.data,
                totalPages: response.data.totalPages,
                totalItems: response.data.totalItems,
                currentPage: page
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch jobs');
        }
    }
);

export const fetchJobById = createAsyncThunk(
    'job/fetchJobById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${endpoints.getJobById}/${id}`);
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch job details');
        }
    }
);

const initialState = {
    allJobs: safeJSONParse(localStorage.getItem('allJobs')) || [],
    selectedJob: null,
    searchQuery: '',
    pagination: {
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 50
    },
    status: 'idle',
    error: null,
    lastUpdated: null
};

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = {
                ...state.pagination,
                ...action.payload
            };
        },
        clearSearch: (state) => {
            state.searchQuery = '';
            state.pagination.currentPage = 1;
        },
        resetJobState: (state) => {
            state.allJobs = [];
            state.selectedJob = null;
            state.searchQuery = '';
            state.pagination = initialState.pagination;
            state.status = 'idle';
            state.error = null;
            localStorage.removeItem('allJobs');
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch Jobs
            .addCase(fetchJobs.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchJobs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.allJobs = action.payload.data;
                state.pagination = {
                    currentPage: action.payload.currentPage,
                    totalPages: action.payload.totalPages,
                    totalItems: action.payload.totalItems,
                    itemsPerPage: state.pagination.itemsPerPage
                };
                state.lastUpdated = new Date().toISOString();
                state.error = null;
                
                // Only cache if not searching
                if (!state.searchQuery) {
                    localStorage.setItem('allJobs', JSON.stringify(action.payload.data));
                }
            })
            .addCase(fetchJobs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch jobs';
            })
            
            // Fetch Job by ID
            .addCase(fetchJobById.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchJobById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedJob = action.payload;
                state.error = null;
            })
            .addCase(fetchJobById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch job details';
            });
    },
});

// Selectors
export const selectAllJobs = (state) => state.job.allJobs;
export const selectJobById = (state) => state.job.selectedJob;
export const selectJobsPagination = (state) => state.job.pagination;
export const selectJobsStatus = (state) => state.job.status;
export const selectJobsError = (state) => state.job.error;
export const selectSearchQuery = (state) => state.job.searchQuery;

export const {
    setSearchQuery,
    setPagination,
    clearSearch,
    resetJobState
} = jobSlice.actions;

export default jobSlice.reducer;