import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Preloader from '../components/preLoaders/Preloader';

const ProtectedRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth);
  const { currentSubscription, status: subscriptionStatus } = useSelector((state) => state.subscription);
  const location = useLocation();

  if (!token) {
    // User is not authenticated
    return <Navigate to="/login" />;
  }

  if (subscriptionStatus === 'loading') {
    // Optionally, render a loading indicator while fetching subscription
    return <Preloader />;
  }

  // Allow access to payment page even without subscription
  if (location.pathname === '/payment') {
    return children;
  }

  if (!currentSubscription) {
    // User is authenticated but has no active subscription
    // Only redirect to plans if not on payment page
    return <Navigate to="/plans" />;
  }

  // User is authenticated and has an active subscription
  return children;
};

export default ProtectedRoute;